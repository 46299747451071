// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div._3jXXtJuRGOgav0Zp7tDgwA\\=\\= {\n  position: relative;\n  width: 100%;\n  padding-top: 50%;\n  border-radius: 10px;\n  box-shadow: 0px 15px 55px rgba(0, 0, 0, 0.1); }\n\ndiv._3jXXtJuRGOgav0Zp7tDgwA\\=\\= div {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 14px; }\n", "",{"version":3,"sources":["webpack://./src/components/Chart/components/ChartLoading.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAGhB,mBAAmB;EACnB,4CAA0C,EAAA;;AAG5C;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe,EAAA","sourcesContent":["div.loading {\n  position: relative;\n  width: 100%;\n  padding-top: 50%;\n  //background: #fafafa;\n  //border: 1px solid #eee;\n  border-radius: 10px;\n  box-shadow: 0px 15px 55px rgba(0,0,0,0.10);\n}\n\ndiv.loading div {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "_3jXXtJuRGOgav0Zp7tDgwA=="
};
export default ___CSS_LOADER_EXPORT___;
